@import url('https://fonts.googleapis.com/css?family=Muli:400,700&display=swap');

* {
  outline: none;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Muli', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
